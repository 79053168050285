:root {
    --main-color: #00B4D8;
    --main-color2: #4097a9;
    --black: #222;
    --white: #fff;
    --light-black: #777;
    --light-white: #fff9;
    --dark-bg: rgba(0, 0, 0, .7);
    --light-bg: #eee;
    --border: .1rem solid var(--black);
    --box-shadow: 1px 5px 10px 5px #ccc;
    --box-shadow2: 10px 10px 10px 10px #ccc;
    --text-shadow: 0 1.5rem 3rem rgba(0, 0, 0, .3);
}

.back-img-1 {
    background-image: url("/src/Images/h3.png");
    width: 25rem;
    height: 30rem;
    border-radius: 10px;
    color: white;
    padding-top: 50%;
    padding-bottom: 50%;
    text-align: center;
}

.padding-for-logo {
    padding-left: 15%;
    padding-right: 5%;
}

.back-img-2 {
    background-image: url("/src/Images/h2.png");
    width: 25rem;
    background-size: cover;
    height: 30rem;
    border-radius: 10px;
    color: white;
    padding-top: 50%;
    padding-bottom: 50%;
    text-align: center;

}

.back-img-3 {
    background-image: url("/src/Images/h1.png");
    width: 25rem;
    height: 30rem;
    background-size: cover;
    border-radius: 10px;
    color: white;
    padding-top: 50%;
    padding-bottom: 50%;
    text-align: center;

}

.back-img-5 {
    background-image: url("/src/Images/contact.png");

    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;

    color: white;
    padding: 30px 30px;
    height: 100%;
}


.back-img-4:hover {
    filter: brightness(0.7);
    transition: 1s;
    cursor: pointer;
}


@media (max-width:600px) {
    .back-img-1 {
        background-image: url("/src/Images/h3.png");
        width: 20rem;
        height: 25rem;
        border-radius: 10px;
        color: white;
        padding-top: 50%;
        padding-bottom: 50%;
        text-align: center;
    }

    .padding-for-logo {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .back-img-2 {
        background-image: url("/src/Images/h2.png");
        width: 20rem;
        background-size: cover;
        height: 25rem;
        border-radius: 10px;
        color: white;
        padding-top: 50%;
        padding-bottom: 50%;
        text-align: center;

    }

    .back-img-3 {
        background-image: url("/src/Images/h1.png");
        width: 20rem;
        height: 25rem;
        background-size: cover;
        border-radius: 10px;
        color: white;
        padding-top: 50%;
        padding-bottom: 50%;
        text-align: center;

    }

    .container .box {
        position: relative;
        width: 20rem !important;
        height: 25rem !important;
        font-family: 'Poppins', sans-serif;
        cursor: pointer;
    }
}

.border-class {
    border: 2px solid white;
    height: 18rem;
    border-radius: 10px;
    padding: 7% 4%;
}


.swiper-button-next,
.swiper-button-prev {
    right: 10px;
    font-size: 20px !important;
    left: auto;
    color: grey !important;

    padding: 5px;
    display: none;
}

.swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after {
    font-size: 20px !important;
    display: none;
}

.swiper-button-prev:after,
.swiper-rtl .swiper-button-next:after {
    font-size: 20px !important;
    display: none;
}

.icon-service {
    font-size: 70px;
    margin-bottom: 1rem;
    color: var(--main-color);
}

.container .box {
    position: relative;
    width: 25rem;
    height: 30rem;
    font-family: 'Poppins', sans-serif;
    cursor: pointer;
}

.display {
    display: none;
}

.container .box:hover .imgBox {
    transform: translate(-3.5rem, -3.5rem);
}

.container .box:hover .display {
    display: block;
    transition: 1s;
}

.container .box:hover .content {
    transform: translate(3.5rem, 3.5rem);
}

.imgBox {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    transition: all 0.5s ease-in-out;
}

.imgBox img {
    width: 25rem;
    height: 30rem;
    object-fit: cover;
    resize: both;
    border-radius: 10px;
}

.content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 1.5rem;
    display: flex;
    box-shadow: var(--box-shadow2);
    justify-content: center;
    background-color: #fff;
    z-index: 1;
    align-items: flex-end;
    text-align: center;
    transition: 0.5s ease-in-out;
}

.content h2 {
    display: block;
    font-size: 1.5rem;
    color: #111;
    font-weight: 500;
    line-height: 2rem;
    letter-spacing: 1px;
}

.content span {
    color: #555;
    font-size: 1.2rem;
    font-weight: 300;
    letter-spacing: 2px;
    margin-left: 2rem;
}

@media (max-width: 600px) {
    .container .box:hover .content {
        transform: translate(0, 3.5rem);
    }

    .container .box:hover .imgBox {
        transform: translate(0, -3.5rem);
    }
}

.shadow {
    box-shadow: var(--box-shadow2);
}

.hover-underline-animation {
    display: inline-block;
    position: relative;
    cursor: pointer;
}

.hover-underline-animation::after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: var(--main-color);
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
}

.hover-underline-animation:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
}

.swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets.swiper-pagination-horizontal {
    padding-top: 1rem;
}


.avatar {
    width: 70px;
}

.container1 .card-5 .icon {
    cursor: pointer;
    position: absolute;

    top: 0;

    left: 0;

    width: 100%;

    height: 100%;

    /* background: var(--main-color); */

}

.container1 .card-5 .icon .fa {

    position: absolute;

    top: 50%;

    left: 50%;

    transform: translate(-50%, -50%);

    font-size: 50px;

    color: #fff;

}

.container1 .card-5 .slide {

    width: 100%;

    height: 240px;
    border-radius: 10px;
    transition: 0.5s;

}

.container1 .card-5 .slide.slide1 {

    position: relative;

    display: flex;

    justify-content: center;

    align-items: center;

    z-index: 1;

    transition: .7s;

    transform: translateY(100px);

}

.container1 .card-5:hover .slide.slide1 {

    transform: translateY(0px);

}

.container1 .card-5 .slide.slide2 {

    position: relative;

    display: flex;

    justify-content: center;

    align-items: center;

    padding: 20px;

    box-sizing: border-box;

    transition: .8s;

    transform: translateY(-100px);

    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.4);

}

.container1 .card-5:hover .slide.slide2 {

    transform: translateY(0);

}

.container1 .card-5 .slide.slide2::after {

    content: "";

    position: absolute;

    width: 30px;

    height: 4px;

    bottom: 15px;

    left: 50%;

    left: 50%;

    transform: translateX(-50%);

    background: var(--main-color);

}

.container1 .card-5 .slide.slide2 .content-2 p {

    margin: 0;

    padding: 0;

    text-align: center;

    color: #414141;

}

.container1 .card-5 .slide.slide2 .content-2 h3 {

    margin: 0 0 10px 0;

    padding: 0;

    font-size: 24px;

    text-align: center;

    color: #414141;

}


.card-small-2 {
    box-shadow: var(--box-shadow);
    border-radius: 10px;
}

.display-2 {
    display: none;
    font-size: 20px;
    transition: 1s;
}

.back :hover .display-2 {
    display: inline-block;
    transition: 1s;
    font-size: 20px;
}

.back {
    background: var(--main-color2);
    /* box-shadow: 0 12px 22px 0 var(--main-color); */
    backdrop-filter: blur(16px);
    -webkit-backdrop-filter: blur(16px);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.18);
}