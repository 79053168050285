/* -----------caursal style start-------- */

.container-banner {
    position: relative;

}

.div-banner {
    margin-left: 1rem;
    margin-top: 1rem;
    color: white;
    top: 8px;
    left: 16px;
    position: absolute;
    z-index: 1;
}

.div-banner2 {
    margin-right: 1rem;
    margin-top: 1rem;
    color: white;
    top: 8px;
    right: 16px;
    position: absolute;
    z-index: 1;
}

.icon-banner {
    font-size: 90px;
    margin-top: 2.5rem;
    margin-right: 2rem;
}

.text-small-banner1 {
    font-size: 35px;
    margin-top: 4rem;
}

.text-small-banner2 {
    font-size: 35px;
    margin-top: 0.5rem;
}

.text-small-banner3 {
    font-size: 20px;
    margin-top: 1rem;
    margin-bottom: 4rem;
}

.text-small-banner4 {
    font-size: 22px;
    margin-top: 2rem;
    margin-bottom: 4rem;
}

.text-small-banner5 {
    font-size: 33px;
    margin-top: 3.7rem;
    margin-bottom: 2rem;
}

@media (max-width:750px) {
    .text-small-banner5 {
        font-size: 23px;
        margin-top: 3.7rem;
        margin-bottom: 2rem;
    }

    .button-animation {
        position: relative;
        display: inline-block;
        margin: 2px;
        padding: 5px 10px;
        text-align: center;
        font-size: 12px;
        letter-spacing: 1px;
        text-decoration: none;
        color: var(--main-color);
        background: transparent !important;
        cursor: pointer;
        transition: ease-out 0.5s;
        border: 2px solid var(--main-color);
        border-radius: 10px;
        box-shadow: inset 0 0 0 0 var(--main-color);
    }

    /* .img-size {
        height: 13.3rem
    } */

    .div-banner {
        margin-left: 0rem;

    }

    .div-banner2 {
        margin-right: 0rem;

    }
}

.font-size-banner {
    font-size: 80px;
}

.font-size-banner2 {
    font-size: 120px !important;
    margin-left: 3rem;
    margin-top: 6rem;
}




@media (min-width: 1450px) and (max-width:1600px) {

    .font-size-slider {

        font-size: 60px !important;

    }

    .font-size-banner2 {
        font-size: 90px !important;
        margin-left: 3rem;
        margin-top: 4rem;
    }

    .icon-banner {
        font-size: 60px;
        margin-top: 1.4rem;
        margin-right: 1rem;
    }

}

.size-1 {
    width: 45rem;
}


.color-hr2 {


    width: 40rem;


}

@media (min-width: 1250px) and (max-width:1450px) {


    .size-1 {
        width: 35rem;
    }

    .font-size-banner2 {
        font-size: 80px !important;
        margin-left: 3rem;
        margin-top: 4rem;
    }

    .font-size-slider {

        font-size: 50px !important;

    }

    .color-hr2 {


        width: 30rem;


    }

    .icon-banner {
        font-size: 40px;
        margin-top: 1.3rem;
        margin-right: 1rem;
    }

    .text-small-banner1 {
        font-size: 35px;
        margin-top: 1rem;
    }

    .text-small-banner2 {
        font-size: 30px;
        margin-top: 0.5rem;
    }

    .text-small-banner3 {
        font-size: 16px;
        margin-top: 1rem;
        margin-bottom: 3rem;
    }

    .text-small-banner4 {
        font-size: 18px;
        margin-top: 1rem;
        margin-bottom: 4rem;
    }

}

@media (min-width: 1000px) and (max-width:1250px) {


    .font-size-slider {

        font-size: 43px !important;

    }

    .font-size-banner2 {
        font-size: 75px !important;
        margin-left: 3rem;
        margin-top: 3rem;
    }

    .size-1 {
        width: 30rem;
    }

    .color-hr2 {


        width: 30rem;


    }


    .icon-banner {
        font-size: 50px;
        margin-top: 1rem;
        margin-right: 1rem;
    }

    .text-small-banner1 {
        font-size: 30px;
        margin-top: 1rem;
    }

    .text-small-banner2 {
        font-size: 25px;
        margin-top: 0.5rem;
    }

    .text-small-banner3 {
        font-size: 15px;
        margin-top: 1rem;
        margin-bottom: 2rem;
    }

    .text-small-banner4 {
        font-size: 15px;
        margin-top: 2rem;
        margin-bottom: 4rem;
    }
}

.icons {
    color: white !important;
    border: 1px solid white;
    padding: 11px;
    font-size: 50px;
    border-radius: 40px;
    margin: 5px !important;
}

@media (min-width: 900px) and (max-width:1000px) {

    .size-1 {
        width: 25rem;
    }

    .font-size-banner2 {
        font-size: 70px !important;
        margin-left: 3rem;
        margin-top: 3rem;
    }


    .font-size-slider {

        font-size: 40px !important;

    }

    .color-hr2 {
        margin-top: 0.2rem;

        width: 25rem;


    }



    .icon-banner {
        font-size: 40px;
        margin-top: 0.5rem;
        margin-right: 0.5rem;
    }

    .text-small-banner1 {
        font-size: 17px;
        margin-top: 1rem;
    }

    .text-small-banner2 {
        font-size: 15px;
        margin-top: 0.5rem;
    }

    .text-small-banner3 {
        font-size: 10px;
        margin-top: 1rem;
        margin-bottom: 2rem;
    }

    .text-small-banner4 {
        font-size: 18px;
        margin-top: 2rem;
        margin-bottom: 4rem;
    }
}

@media (min-width: 800px) and (max-width:900px) {

    .size-1 {
        width: 23rem;
    }

    .font-size-slider {

        font-size: 40px !important;

    }

    .color-hr2 {
        margin-top: 0.2rem;

        width: 25rem;


    }

    .font-size-banner2 {
        font-size: 60px !important;
        margin-left: 2rem;
        margin-top: 3rem;
    }

    .icon-banner {
        font-size: 40px;
        margin-top: 0.5rem;
        margin-right: 0.5rem;
    }

    .text-small-banner1 {
        font-size: 17px;
        margin-top: 1rem;
    }

    .text-small-banner2 {
        font-size: 15px;
        margin-top: 0.5rem;
    }

    .text-small-banner3 {
        font-size: 10px;
        margin-top: 1rem;
        margin-bottom: 2rem;
    }

}


@media (min-width: 700px) and (max-width:800px) {

    .size-1 {
        width: 23rem;
    }

    .font-size-slider {

        font-size: 28px !important;

    }

    .color-hr2 {
        margin-top: 0.2rem;

        width: 25rem;


    }

    .font-size-banner2 {
        font-size: 55px !important;
        margin-left: 1rem;
        margin-top: 3rem;
    }

    .icon-banner {
        font-size: 40px;
        margin-top: 0.5rem;
        margin-right: 0.5rem;
    }

    .text-small-banner1 {
        font-size: 15px;
        margin-top: 1rem;
    }

    .text-small-banner2 {
        font-size: 15px;
        margin-top: 0.5rem;
    }

    .text-small-banner3 {
        font-size: 12px;
        margin-top: 1rem;
        margin-bottom: 2rem;
    }

}

@media (max-width: 700px) {

    .font-size-slider {
        margin-top: -0.2rem;
        font-size: 15px !important;

    }

    .font-size-banner2 {
        font-size: 30px !important;
        margin-left: 0rem;
        margin-top: 1rem;
    }

    .size-1 {
        width: 17rem;
    }

    .div-banner {
        margin-left: auto;
        margin-top: -0.2rem;
        margin-right: auto;
    }



    .icon-banner {
        font-size: 30px;
        margin-top: 0.5rem;
        margin-right: 0.5rem;
    }

    .text-small-banner1 {
        font-size: 10px;
        margin-top: 0.2rem;
    }

    .text-small-banner2 {
        font-size: 11px;
        margin-top: 0rem;
    }

    .text-small-banner3 {
        font-size: 9px;
        margin-top: -0.5rem;
        margin-bottom: 0.5rem;
    }

    .text-small-banner4 {
        font-size: 9px;
        margin-top: 0.5rem;
        margin-bottom: 1rem;
    }

    .color-hr2 {


        width: 14rem;


    }

    .icons {
        color: white !important;
        border: 1px solid white;
        padding: 11px;

        font-size: 50px;
        border-radius: 40px;
        margin: 1px !important;

    }
}



.font-size-slider {
    color: var(--white);
    font-size: 70px;
    text-transform: uppercase;
}


@media (max-width: 500px) {
    .img-size {
        height: 13rem;
    }
}


/* -----------caursal style end-------- */