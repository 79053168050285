.formInput {
    display: flex !important;

}

.inputfiled {
    padding: 1%;
}

.font-size {
    font-size: 16px;
    color: grey;
}

.font-size2 {
    font-size: 25px;
}

.edit-info {
    list-style: none;
    z-index: 99;
    font-size: 15px;
    width: 10rem;
    position: absolute;
    background-color: white;
    padding: 1%;
    right: 0;
}

.dots {
    cursor: pointer;
    font-size: 30px;
}

.card-5 {
    box-shadow: 0 5px 25px rgba(114, 112, 112, 0.3), 0 px 2px rgba(0, 0, 0, 0.22);
    border-radius: 10px;
}