:root {
    --main-color: #00B4D8;
    --black: #222;
    --white: #fff;
    --light-black: #777;
    --light-white: #fff9;
    --dark-bg: rgba(0, 0, 0, .7);
    --light-bg: #eee;
    --border: .1rem solid var(--black);
    --box-shadow: 1px 5px 10px 5px #ccc;
    --box-shadow2: 10px 10px 10px 10px #ccc;
    --text-shadow: 0 1.5rem 3rem rgba(0, 0, 0, .3);
}

/* Card---small---- */

.div-icons {
    width: 5rem;
    margin-top: auto;
    margin-bottom: auto;
}


.icons-small {
    background-color: var(--main-color);
    color: white;
    width: 3rem;
    font-size: 50px !important;
    border-radius: 10px;
    padding: 10px;
    cursor: pointer;
}

.card {
    height: 273px !important;
    font-size: 28px;
    font-weight: bold;
    cursor: pointer;
    text-align: center;
    margin-top: auto;
    color: var(--main-color) !important;
    margin-bottom: auto;
    padding: 106px 0px;
    box-shadow: var(--box-shadow);
}


.card:hover {

    transition: 1s;
    color: white !important;
    background: var(--main-color);
}



/*------ card-style-end------ */