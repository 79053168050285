/* contact us------ */

.icon-social {
    text-align: center;
    align-items: center;
    justify-content: center;
    display: flex;
}



.marginFooter {
    margin-top: 0.7rem;
}

/* .zoom{
   
} */


.strip-contact {
    display: flex;

}



@media (min-width:1200px) {
    .padding2 {
        padding-left: 5%;
        padding-right: 5%;
    }
}


/* contact us end----- */