@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.react-tel-input .form-control {
  width: 100% !important;
}

@media only screen and (max-width:480px) {


  html,
  body {
    overflow-x: hidden !important;
  }

  ::-webkit-scrollbar {
    width: 15px;
    height: 1em;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 5px;
  }

  .logo {
    width: 100px;
    height: 60px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #123F47;
    border-radius: 5px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #123F47;
  }
}

.img-circle {
  width: 200px;
  height: 200px;

  animation-name: rotate;
  animation-duration: 20s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  border-radius: 50%;
}


:root {
  --main-color: #123F47;
  --black: #222;
  --white: #fff;
  --light-black: #777;
  --light-white: #fff9;
  --dark-bg: rgba(0, 0, 0, .7);
  --light-bg: #eee;
  --border: .1rem solid var(--black);
  --box-shadow: 1px 5px 10px 5px #ccc;
  --box-shadow2: 10px 10px 10px 10px #ccc;
  --box-shadow3: 10px 10px 10px 5px #ccc;
  --text-shadow: 0 1.5rem 3rem rgba(0, 0, 0, .3);
}


::-webkit-scrollbar {
  width: 15px;
  height: 1em;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

.logo {
  width: 100px;
  height: 60px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #123F47;
  border-radius: 2px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--main-color);
}

.font-size-para {
  font-size: 15px;
}


@media (min-width:1600px) {
  .padding3 {
    padding-left: 8%;
    padding-right: 8%;
  }


  .padding-4 {
    padding-left: 15%;
    padding-right: 15%;
  }
}


.link-dec {
  color: white;
  text-decoration: none;


}

.link-dec:hover {
  color: white;

}


.color-hover:hover {
  color: var(--main-color)
}

.size-para {
  font-size: 17px;
}

.color {
  color: var(--main-color);
  text-decoration: none;
}

.center {
  text-align: center !important;
}




@media (max-width: 1400px) {
  .container {
    max-width: 100%;
  }

}


.bold-text {
  font-weight: bold !important;

}


/* .padding-1 {
  padding-left: 22%;
  padding-right: 22%;
} */

.padding {
  padding: 2%;
}

.color2 {
  /* background: #00B4D8; */
  background: linear-gradient(to right, #00B4D8 0%, #00B4D8 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.color-hr {
  color: white;


}

.color-hr2 {

  border: 2px solid white
}



.color4 {
  background: #00B4D8;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);

  border: 1px solid rgba(255, 255, 255, 0.18);
}




.tran {
  transition: 2s;
}




/* --------how page------ */


.font2 {
  font-size: 22px !important;
}

.drop-para {
  border: 1px solid #00B4D8 !important;
  border-radius: 10px !important;
  padding: 1% !important;
  margin-top: 1rem;
}

.shadow {
  padding: 1%;
  border-radius: 10px;
  /* box-shadow: var(--box-shadow) !important; */
}

.shadow2 {


  box-shadow: 10px 5px 20px 1px #bbb;
}



/* --------where--page----- */


.image {
  display: block;
  width: 100%;
  height: auto;
}



.text {
  color: white;
  font-size: 20px;
  position: absolute;
  margin-top: 10%;
  padding: 5%;

  text-align: center;
}


/* ----where end------ */


.just-con {
  justify-content: space-between !important;
  display: flex;
}

@media (max-width:620px) {
  .just-con {

    display: inline-block;
  }

  .text-block {
    margin-bottom: 2rem;
    margin-left: 1rem;
  }


}

.margin-top {
  margin-top: 5rem;
}

@media(min-width:800px) and (max-width:1400px) {

  .margin-top {
    margin-top: 2rem;
  }

  .margin-top2 {
    margin-top: 2rem;
  }

  .margin-top4 {
    margin-top: 0.3rem;
  }

}

@media(min-width:800px) {

  .margin-top2 {
    margin-top: 2rem;
  }
}

.card-faq {
  padding: 3%;
  background-color: white;
}


.button-animation {
  position: relative;
  display: inline-block;
  margin: 5px;
  padding: 10px 20px;
  text-align: center;
  font-size: 18px;
  letter-spacing: 1px;
  text-decoration: none;
  color: var(--main-color);
  background: transparent !important;
  cursor: pointer;
  transition: ease-out 0.5s;
  border: 2px solid var(--main-color);
  border-radius: 10px;
  box-shadow: inset 0 0 0 0 var(--main-color);
}

.button-animation:hover {
  color: white;
  box-shadow: inset 0 -100px 0 0 var(--main-color);
}

.button-animation:active {
  transform: scale(0.9);
}





.back-img-how-back {
  background-image: url("/src/Images/how-back.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  color: white;
  padding: 30px 30px;
  height: 100%;
}






/* ---small-trip---- */
.icon-social-right {


  top: 8rem;
  color: white;
  left: 16px;
  position: absolute;
  z-index: 1;
}

.back-img {

  width: 100%;

}




@media (min-width:900px) and (max-width:1600px) {
  .back-img {
    width: 80%;
    height: 250px;

  }

  .icon-social-right {
    font-size: 20px;
    top: 6.5rem;
  }
}

@media (min-width:700px) and (max-width:900px) {
  .back-img {

    height: 180px;
  }

  .margin-top2 {
    margin-top: 2rem;
  }

  .icon-social-right {


    top: 6rem;
    color: white;
    right: -19px;
    position: absolute;
    z-index: 1;
  }

  .icon-social-right {
    font-size: 20px;
  }
}

@media (max-width:700px) {
  .back-img {
    height: 120px;

  }

  .marginTop-500 {
    margin-top: -2.4rem;
  }

  .icon-social-right {


    top: 6rem;
    color: white;
    right: -10px;
    position: absolute;
    z-index: 1;
  }

  .icon-social-right {
    font-size: 12px;
  }
}

.float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #25d366;
  color: #FFF;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
}

.my-float {
  margin-top: 12px;
}