.contact-us {
    padding: 5%;
    color: white !;
}

.paragraph-small {
    font-size: 20px;
    margin-left: 0.3rem;
    color: black;
    margin-bottom: 0.2rem;
}

.form-control {
    border-radius: 14px;
    padding: 1.4%;
}


.padding-2 {
    padding-right: 10%;
    padding-left: 10%;
}